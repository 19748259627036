import CustomFilterLayout from '../../../components/CustomFilter/CustomFilterLayout';
import CustomNavbar from '../../../components/Navs/CustomNavbar';
import CustomCta from '../../../components/StaticBlocks/CustomCta';
import CustomCtaWithEffect from '../../../components/StaticBlocks/CustomCtaWithEffect';
import DynamicTitleDescriptionButton from '../../../components/StaticBlocks/DynamicTitleDescriptionButton';
import GridCardBlock from '../../../components/StaticBlocks/GridCardBlock';
import LogoutButton from '../../../components/StaticBlocks/LogoutButton';
import Paragraph from '../../../components/StaticBlocks/Paragraph';
import TextWithIcon from '../../../components/StaticBlocks/TextWithIcon';
import SubscriptionByCard from '../../../components/Subscription/SubscriptionByCard';
import SubscriptionsList from '../../../components/Subscription/SubscriptionsList';
import WelcomeHeadingTitle from '../../../components/StaticBlocks/WelcomeHeadingTitle';
import MultiWalletCurrencies from '../../../components/Wallet/MultiCurrencies/MultiWalletCurrencies';
import WalletDetails from '../../../components/Wallet/WalletDetails';
import HubspotContactForm from '../../../components/StaticBlocks/HubspotContactForm';
import TallyContactUSForm from '../../../components/StaticBlocks/TallyContactUSForm';
import AccountCustomFilterLayout from '../../../components/AccountCustomFilter/AccountCustomFilterLayout';
import DynamicStoresListView from '../../../components/home/Stores/DynamicStoresListView';
import SingleListingCard from '../../../components/Listings/SingleListingCard';
import Accordion from '../../../components/StaticBlocks/Accordion';
import ComposerDescriptionBlock from '../../../components/StaticBlocks/ComposerDescriptionBlock';
import CustomDynamicCodeBlock from '../../../components/StaticBlocks/CustomDynamicCodeBlock';
import DividerBlock from '../../../components/StaticBlocks/DividerBlock';
import OrderSummary3 from '../../../components/Cart/OrderSummary/OrderSummary3';
import GlobalOrderSummary from '../../../components/Cart/OrderSummary/GlobalOrderSummary';
import GlobalCartItemBox from '../../../components/Cart/CartItemBox/GlobalCartItemBox';
import GlobalCheckoutButton from '../../../components/Cart/Checkout/GlobalCheckoutButton';
import NotificationsList from '../../../components/NotificationsButton/NotificationsList';

export const common_blocks_cases = ({ section }) => {
  const {
    order_number,
    block,
    block_data_key,
    fetch_by,
    data,
    isStatic,
    extra_data,
    styles,
    isEnabled = true,
  } = section || {};
  switch (block) {
    case 'custom_navbar':
      return <CustomNavbar data={data} styles={styles} key={order_number} />;
    case 'multi_wallet_buttons':
      return <MultiWalletCurrencies styles={styles} key={order_number} />;
    case 'wallet_details':
      return <WalletDetails styles={styles} key={order_number} />;
    case 'custom_filter':
      return (
        <CustomFilterLayout
          styles={styles}
          data={data}
          extra_data={extra_data}
          key={order_number}
        />
      );
    case 'custom_account_filter':
      return (
        <AccountCustomFilterLayout
          styles={styles}
          data={data}
          extra_data={extra_data}
          key={order_number}
        />
      );
    case 'grid_card_block_top_image':
      return <GridCardBlock data={data} styles={styles} key={order_number} />;
    case 'dynamic_title_desc_button_top':
      return (
        <DynamicTitleDescriptionButton
          data={data}
          styles={styles}
          key={order_number}
        />
      );
    case 'subscription_by_card':
      return (
        <SubscriptionByCard
          data={data}
          styles={styles}
          extra_data={extra_data}
        />
      );
    case 'text_with_icon':
      return (
        <TextWithIcon data={data} styles={styles} extra_data={extra_data} />
      );
    case 'subscription_list':
      return <SubscriptionsList data={data} styles={styles} />;

    case 'menu_cta':
      return <CustomCtaWithEffect data={data} styles={styles} />;
    case 'logout_cta':
      return <LogoutButton data={data} styles={styles} />;
    case 'welcome_heading_title':
      return (
        <WelcomeHeadingTitle data={data} styles={styles} check_by={'user'} />
      );
    case 'tally_form_block':
      return isEnabled && <TallyContactUSForm data={data} styles={styles} />;
    case 'hubspot_form_block':
      return isEnabled && <HubspotContactForm data={data} styles={styles} />;
    case 'dynamic_all_accounts':
      return (
        <DynamicStoresListView data={data} styles={styles} key={order_number} />
      );
    case 'single_listing_card':
      return (
        <SingleListingCard data={data} styles={styles} key={order_number} />
      );
    case 'accordion_block':
      return <Accordion data={data} styles={styles} key={order_number} />;
    case 'description_with_composer':
      return (
        <ComposerDescriptionBlock
          data={data}
          styles={styles}
          key={order_number}
        />
      );
    case 'dynamic_code_block':
      return (
        <CustomDynamicCodeBlock
          data={data}
          styles={styles}
          key={order_number}
        />
      );
    case 'divider_block':
      return <DividerBlock styles={styles} />;

    case 'global_order_summary':
      return isEnabled && <GlobalOrderSummary data={data} styles={styles} />;
    case 'global_cart_list':
      return isEnabled && <GlobalCartItemBox data={data} styles={styles} />;
    case 'global_checkout_button':
      return isEnabled && <GlobalCheckoutButton data={data} styles={styles} />;
    case 'notifications_list':
      return isEnabled && <NotificationsList data={data} styles={styles} />;

    default:
      return <></>;
  }
};
